import { DatatableInit } from "./init"
import { DEFAULT_OPTIONS } from "./init"
// import { DEFAULT_COLUMNDEFS } from "./init"

export class TasksDatatable extends DatatableInit {
  // customize datatables for tasks dataset
  constructor () {
    let columndefs = [
      { width: "30%", targets: [0] },
      { width: "20%", targets: [1] },
      {
        targets: [-2, -1],
        orderable: false,
        searchable: false,
        className: "text-center"
      },
      {
        targets: [6],
        searchable: false,
        className: "text-center"
      },
      {
        targets: [2, 3, 4, 5],
        className: "text-center"
      }
    ]
    let options = Object.assign({}, DEFAULT_OPTIONS)
    options.order = [[0, "desc"]]
    super("tasks_table", columndefs, options)

    // refresh tasks grid every minute
    setInterval(() => {
      $("#tasks_table").DataTable().ajax.reload(null, false)
    }, 60000)
  }
}
