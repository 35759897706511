export default class MultipleIndex extends ApplicationController {

  expandUser (e) {
    const cards = document.querySelectorAll(".multiple-user-card")
    cards.forEach(element => {
      element.classList.toggle("h-150")
    })
    const userId = e.currentTarget.dataset.userId
    document.getElementById(`${userId}_chevron-bottom`).classList.toggle("d-none")
    document.getElementById(`${userId}_chevron-top`).classList.toggle("d-none")
  }
}
