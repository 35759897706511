import { Barcoder } from '../../shared/barcoder'
export default class TasksNew extends ApplicationController {
  static targets = [
    'form', 'project', 'dayEnd', 'fields', 'confirmPanel', 'cancel', 'confirm', 'confirmMessage', 'user', 'submit',
    'lat', 'lon', 'geolocationError', 'geolocationPin'
  ]
  static values = {
    geolocated: Boolean,
    onlyScanner: Boolean
  }

  connect () {
    this.creating = (document.getElementById('new_task') !== null)
    if (this.onlyScannerValue) {
      this.formTarget.addEventListener('keydown', this.onlyScannerHandler.bind(this))
     } else if (this.creating) {
      this.formTarget.addEventListener('keypress', this.regularHandler.bind(this))
     } else { // editing by an admin
      this.projectTarget.value = this.projectTarget.value.padStart(6, '0')
    }
    this.focusAndSelectProject()

    this.geolocated = (this.geolocatedValue && this.hasGeolocationPinTarget)
    if (this.geolocated) this.prepareGeolocation()

    this.projectTarget.addEventListener('input', (event) => {
      if (this.projectTarget.value.length > 0) {
        this.submitTarget.removeAttribute('disabled')
      } else {
        this.submitTarget.setAttribute('disabled', 'true')
      }
    })

    this.formTarget.addEventListener('submit', (event) => {
      if (this.confirming || !this.creating) return

      event.preventDefault()
      event.stopPropagation()
      this.showConfirmPanel()
    })
  }

  disconnect () {
    if (this.onlyScannerValue) {
      this.formTarget.removeEventListener('keydown', this.onlyScannerHandler.bind(this))
     } else if (this.creating) {
      this.formTarget.removeEventListener('keypress', this.regularHandler.bind(this))
    }
  }

  keypressHandler (event) {
    const digit = event.key
    if (digit >= '0' && digit <= '9') {
      event.preventDefault()
      this.projectTarget.value = this.projectTarget.value + digit.toString()
      if (this.projectTarget.value.length == 6) {
        this.finalizeEnteringCode(event)
      }
    }
  }

  onlyScannerHandler (event) {
    event.preventDefault()
    if (event.key == 'Enter') {
      event.preventDefault()
      this.finalizeEnteringCode(event)
    } else if (event.key == 'Backspace') {
      this.projectTarget.value = this.projectTarget.value.slice(0, -1)
    } else {
      const digit = event.key
      if (digit >= '0' && digit <= '9') {
        this.projectTarget.value = this.projectTarget.value + digit.toString()
      }
    }
  }

  regularHandler (event) {
    const digit = event.key
    if (digit >= '0' && digit <= '9') {
      event.preventDefault()
      this.projectTarget.value = this.projectTarget.value + digit.toString()
    } else if (event.key == 'Enter') {
      event.preventDefault()
      this.finalizeEnteringCode(event)
    }
  }

  finalizeEnteringCode (event) {
    event.preventDefault()
    event.stopPropagation()
    const value = this.projectTarget.value
    let project = null
    if (this.onlyScannerValue) {
      project = this.checkScannedCode(value)
    } else {
      project = this.checkProjectCode(value)
    }
    if (project) {
      this.projectTarget.value = project
      if (this.creating) {
        this.showConfirmPanel()
      } else {
        this.submitTarget.click()
      }
    } else {
      this.focusAndSelectProject()
    }
  }

  checkScannedCode (project) {
    if ((project.length == 13) || (project.length == 12 && project.startsWith('0'))) {
      if (project.length == 12) project = '0' + project // some barcodes need a leading 0 when code starts with 0
      if (new Barcoder().validate(project)) {
        return this.projectTarget.value = project.slice(6,12)
      } else {
        this.finalizeScanningWithError('project.invalid_ean_code')
      }
    }
  }

  checkProjectCode (project) {
    if ((project.length == 13) || (project.length == 12 && project.startsWith('0'))) {
      if (project.length == 12) project = '0' + project // some barcodes need a leading 0 when code starts with 0
      if (new Barcoder().validate(project)) {
        return this.projectTarget.value = project.slice(6,12)
      } else {
        this.finalizeScanningWithError('project.invalid_ean_code')
      }
    } else {
      const validRegex = /^\d{1,6}$/
      if (validRegex.test(project)) {
        return project
      } else {
        this.finalizeScanningWithError('project.invalid_project')
      }
    }
  }

  focusAndSelectProject () {
    this.dayEndTarget.value = false
    if (this.creating) {
      this.projectTarget.value = ''
      this.projectTarget.focus()
    }
    if (this.projectTarget.value.length == 0) this.submitTarget.setAttribute('disabled', 'true')
  }

  showConfirmPanel (options) {
    if (!this.creating) return

    let key
    if (this.dayEndTarget.value == 'true') {
      key = 'confirm_day_end'
    } else {
      key = 'confirm_project'
    }
    this.prepareConfirmPanel(key)
    this.makeVisible(this.fieldsTarget, false)
    this.makeVisible(this.confirmPanelTarget, true)
    this.confirmPanelInterval = setInterval(() => {
      this.confirmMessageTarget.innerHTML = this.buildConfirmationText(key)
    }, 1000)
  }

  buildConfirmationText (key) {
    return I18n.t(
      `frontend.task.${key}`,
      {user: this.userTarget.innerText, hour: moment().format('HH:mm'), project: this.projectTarget.value}
    )
  }

  prepareConfirmPanel (key) {
    this.confirmMessageTarget.innerHTML = this.buildConfirmationText(key)
    if (key == 'confirm_day_end') {
      this.confirmPanelTarget.classList.remove('bg-gradient-primary')
      this.confirmPanelTarget.classList.add('bg-gradient-warning')
    } else {
      this.confirmPanelTarget.classList.remove('bg-gradient-warning')
      this.confirmPanelTarget.classList.add('bg-gradient-primary')
    }
  }

  confirm () {
    this.confirming = true
    this.submitTarget.removeAttribute('disabled')
    this.submitTarget.click()
  }

  cancel () {
    this.confirming = false
    this.makeVisible(this.fieldsTarget, true)
    this.makeVisible(this.confirmPanelTarget, false)
    if (this.confirmPanelInterval) clearInterval(this.confirmPanelInterval)
    this.focusAndSelectProject()
  }

  applyDayEnd (e) {
    this.projectTarget.value = e.target.innerText
    this.dayEndTarget.value = true
    this.submitTarget.removeAttribute('disabled')
    this.submitTarget.click()
  }

  finalizeScanningWithError (errorMsg) {
    this.toastError(errorMsg)
    if (this.onlyScanner) {
      this.projectTarget.value = ''
    }
  }

  prepareGeolocation () {
    if(!navigator.geolocation) {
      this.showGeolocationError()
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latTarget.value = position.coords.latitude
          this.lonTarget.value = position.coords.longitude
          this.geolocationErrorTarget.value = 0
          this.geolocationPinTarget.classList.add('bg-success')
          this.makeVisible(this.geolocationPinTarget, true)
        },
        (error) => {
          this.showGeolocationError()
          this.geolocationErrorTarget.value = error.code
          switch(error.code) {
            case error.PERMISSION_DENIED:
              this.toastError('geolocation.permission_denied')
              break
            case error.POSITION_UNAVAILABLE:
              this.toastError('geolocation.position_unavailable')
              break
            case error.TIMEOUT:
              this.toastError('geolocation.timeout')
              break
            case error.UNKNOWN_ERROR:
              this.toastError('geolocation.unknown_error')
              break
          }
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      )
    }
  }

  showGeolocationError () {
    this.geolocationPinTarget.classList.add('bg-danger')
    this.geolocated = false
    this.makeVisible(this.geolocationPinTarget, true)
  }

  toastError (key) {
    this.toast(
      'Error',
      I18n.t(`frontend.${key}`),
      'error'
    )
  }

  makeVisible (target, visible) {
    target.classList.toggle('d-none', !visible)
  }
}
