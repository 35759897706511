import moment from "moment"

export default class Daterangepicker extends ApplicationController {
  static targets = ['picker', 'start', 'end', 'display']

  connect () {
    this.DEFAULT_FORMAT = 'dddd, DD/MM/YYYY'
    // Date range picker
    this.INITIAL_START = this.today()
    this.INITIAL_END = this.today()
    const displayRange = this.displayRange.bind(this)
    displayRange(this.INITIAL_START, this.INITIAL_END)
    this.LOCALE = {
      separator: " - ",
      format: I18n.t('frontend.daterangepicker.format'),
      applyLabel: I18n.t('frontend.daterangepicker.applyLabel'),
      cancelLabel: I18n.t('frontend.daterangepicker.cancelLabel'),
      fromLabel: I18n.t('frontend.daterangepicker.fromLabel'),
      toLabel: I18n.t('frontend.daterangepicker.toLabel'),
      customRangeLabel: I18n.t('frontend.daterangepicker.customRangeLabel'),
      firstDay: 1,
      "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
      ],
      "monthNames": [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
      ]
    }
    let ranges = {}
    ranges[I18n.t('frontend.daterangepicker.today')] = [moment(), moment()]
    ranges[I18n.t('frontend.daterangepicker.yesterday')] = [this.yesterday(), this.yesterday()]
    ranges[I18n.t('frontend.daterangepicker.this_week')] = [moment().startOf('week'), moment().endOf('week')]
    ranges[I18n.t('frontend.daterangepicker.last_week')] = [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')]
    ranges[I18n.t('frontend.daterangepicker.this_month')] = [moment().startOf('month'), moment().endOf('month')]
    ranges[I18n.t('frontend.daterangepicker.last_month')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

    $(this.pickerTarget).daterangepicker({
      ranges: ranges,
        startDate: this.INITIAL_START,
        endDate: this.INITIAL_END,
        locale: this.LOCALE
      }).on('apply.daterangepicker', (ev, picker) => { displayRange(picker.startDate, picker.endDate) }
    )
  }

  today () {
    return moment()
  }

  yesterday () {
    return moment().subtract(1, 'days')
  }

  sameDate (a, b) {
    return moment(a).diff(moment(b), 'days') === 0
  }

  displayRange (start, end) {
    this.startTarget.value = start
    this.endTarget.value = end
    if (this.sameDate(start, end) && this.sameDate(start, this.today())) {
        this.displayTarget.innerHTML = I18n.t('frontend.daterangepicker.today').toUpperCase()
    } else if (this.sameDate(start, end) && this.sameDate(start, this.yesterday())) {
      this.displayTarget.innerHTML = I18n.t('frontend.daterangepicker.yesterday').toUpperCase()
    } else if (this.sameDate(start, end)) {
      this.displayTarget.innerHTML = start.format(this.DEFAULT_FORMAT)
    } else {
      this.displayTarget.innerHTML = start.format(this.DEFAULT_FORMAT) + ' - ' + end.format(this.DEFAULT_FORMAT)
    }
  }
}
