import "./datatables"
import "./ladda"
import "./sweetalert"
import "./bootstrap-datepicker"

// important: all utilities needed by backend generated js must be
// assigned to global window here:
import JQuery from "jquery"
window.$ = window.JQuery = JQuery
import Swal from "sweetalert2"
window.Swal = Swal
import IziToast from "izitoast/dist/js/iziToast.min.js"
window.IziToast = IziToast
// import { i18n } from "./config/i18n";
import { I18n } from "i18n-js";
import translations from "locales.json";
export const i18n = new I18n();
i18n.store(translations);
i18n.defaultLocale = "es";
window.I18n = i18n
require("@nathanvda/cocoon")
import moment from "moment"
window.moment = moment
require("moment/locale/es")
require("daterangepicker/daterangepicker")
